import { useState } from "react";
import clsx from "clsx";
import { Maybe } from "@clubofcode/types";

type Props = {
  locations: Maybe<string | undefined>[];
  limit?: number;
};

/**
 * When a job posting has many locations associated to it,
 * we want to shorten them with the possibility for the user to expand them.
 * Otherwise our ui would 'break'.
 *
 * @param locations The locations, e.g. Berlin, Frankfurt, ...
 * @param limit The maximum number of locations to be shown if shortened.
 */
const JobPostingLocations = ({ locations, limit = 3 }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggle = () => setIsExpanded(!isExpanded);
  const total = locations.length;
  const offset = total - limit;
  const expandable = total > limit;

  return (
    <div>
      {isExpanded ? locations.join(", ") : locations.slice(0, limit).join(", ")}
      {expandable && (
        <>
          &nbsp;
          <span
            onClick={(e) => {
              e.preventDefault();
              toggle();
            }}
            className={clsx("hover:underline cursor-pointer", {
              "text-black-secondary": isExpanded,
            })}
          >
            {isExpanded
              ? "verstecken"
              : `und ${offset} ${
                  offset === 1 ? "weiterer Standort" : "weitere Standorte"
                }`}
          </span>
        </>
      )}
    </div>
  );
};

export default JobPostingLocations;
