import { Maybe } from "@clubofcode/types";
import { Render, Tooltip } from "@clubofcode/ui";

type Props<T> = {
  job: T;
} & React.HTMLProps<HTMLDivElement>;

type MinimalJobPosting = {
  details?: Maybe<{
    isJunior: boolean;
    isIntermediate: boolean;
    isSenior: boolean;
  }>;
};

const JobPostingWorkExperience = <T extends MinimalJobPosting>({
  job,
  ...divProps
}: Props<T>) => {
  return (
    <div {...divProps}>
      <Render if={job.details?.isJunior}>
        <Tooltip
          text={
            <p className="text-sm max-w-xs whitespace-pre-wrap">
              Bis 2 Jahre Berufserfahrung.
            </p>
          }
          placement="bottom-start"
        >
          <span className="text-sm bg-green-200 border border-green-400 text-green-600 font-medium rounded py-0.5 px-1.5">
            Junior
          </span>
        </Tooltip>
      </Render>
      <Render if={job.details?.isIntermediate}>
        <Tooltip
          text={
            <p className="text-sm max-w-xs whitespace-pre-wrap">
              2 bis 5 Jahre Berufserfahrung.
            </p>
          }
          placement="bottom-start"
        >
          <span className="text-sm bg-yellow-200 border border-yellow-400 text-yellow-700 font-medium rounded py-0.5 px-1.5">
            Mid-level
          </span>
        </Tooltip>
      </Render>
      <Render if={job.details?.isSenior}>
        <Tooltip
          text={
            <p className="text-sm max-w-xs whitespace-pre-wrap">
              Über 5 Jahre Berufserfahrung.
            </p>
          }
          placement="bottom-start"
        >
          <span className="text-sm bg-red-200 border border-red-400 text-red-600 font-medium rounded py-0.5 px-1.5">
            Senior
          </span>
        </Tooltip>
      </Render>
    </div>
  );
};

export default JobPostingWorkExperience;
