import { OpenInNewTabIcon } from "@clubofcode/ui";
import { AppLink } from "~/components/Link";
import { SearchJobPostings } from "~/types/graphql.generated";

type Props = {
  job: NonNullable<SearchJobPostings["searchJobPostings"]["nodes"][0]>;
};

const JobPostingSalary = ({ job }: Props) => {
  if (job.__typename === "SearchJobPostingExternal") {
    return (
      <a
        href={job.url!}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center gap-x-1 whitespace-nowrap text-indigo-500 hover:underline"
      >
        <OpenInNewTabIcon strokeWidth={1} size={16} />
        Zur Stellenausschreibung
      </a>
    );
  } else if (job.__typename === "SearchJobPostingInternal") {
    return (
      <>
        <div className="flex items-center justify-end gap-x-2">
          <div className="rounded bg-gray-300 h-6 md:h-8 w-20 md:w-28" />
          <div className="rounded bg-gray-500 h-0.5 md:h-1 w-3 md:w-4" />
          <div className="rounded bg-gray-300 h-6 md:h-8 w-20 md:w-28" />
        </div>
        <p className="text-gray-500 mt-1 text-sm md:text-base">
          <AppLink to="/signup" className="text-indigo-500 hover:underline">
            Registrieren
          </AppLink>
          , um Gehalt zu sehen.
        </p>
      </>
    );
  }

  return null;
};

export default JobPostingSalary;
