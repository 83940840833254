import { isNumber } from "@clubofcode/shared";
import { FindByTypename } from "@clubofcode/types";
import {
  BriefcaseIcon,
  CheckItem,
  ClockIcon,
  DollarCurrencyIcon,
  MapPinIcon,
  Render,
  Title
} from "@clubofcode/ui";
import { Fragment } from "react";

import { AppLink } from "~/components/Link";
import { SearchJobPostings } from "~/types/graphql.generated";

import JobPostingLocations from "./JobPostingLocations";
import JobPostingSalary from "./JobPostingSalary";
import JobPostingWorkExperience from "./JobPostingWorkExperience";

type Props = {
  job: FindByTypename<
    NonNullable<
      NonNullable<SearchJobPostings["searchJobPostings"]["nodes"]>[0]
    >,
    "SearchJobPostingInternal"
  >;
};

function getLocationsString(numOfLocations: number) {
  if (numOfLocations === 1) {
    return "(1 Standort)";
  } else if (numOfLocations > 1) {
    return `(${numOfLocations} Standorte)`;
  } else {
    return null;
  }
}

const JobSearchResultInternal = ({ job }: Props) => {
  const numOfLocations = job.businessLocations?.length ?? 0;
  const hasLocations = !!numOfLocations;
  const locations = getLocationsString(numOfLocations);
  const isLatestVersion = job.version === "2022_10_11";

  return (
    <Fragment key={job.id}>
      <div className="flex border-b border-gray-300 pb-3">
        <div className="flex-grow mr-2 overflow-x-hidden">
          {/* TODO: improve :id param to include title of job for better SEO */}
          <Title as="h6" className="text-xl lg:text-2xl xl:text-3xl font-bold">
            {job.title}
          </Title>
          <div className="flex items-center gap-x-4">
            <p>
              bei <b>{job.companyName}</b>
            </p>
            <Render if={isLatestVersion}>
              <div className="w-[1px] h-4 border-r border-gray-300" />
              <JobPostingWorkExperience
                job={job}
                className="flex items-center gap-x-1"
              />
              <div className="w-[1px] h-4 border-r border-gray-300" />
              <div className="inline-flex gap-x-3">
                {job.languages.map((lang) => (
                  <span key={lang} className="font-gray-700">
                    {lang}
                  </span>
                ))}
              </div>
            </Render>
          </div>
          <ul className="flex flex-wrap mb-1 gap-y-1 gap-x-2 sm:gap-x-6 font-medium text-gray-700 mt-1 text-sm sm:text-base">
            <li className="flex items-center">
              <BriefcaseIcon size={16} className="relative top-[-2px] mr-1" />
              {job.details?.jobType}
            </li>
            <li className="flex items-center">
              <ClockIcon size={16} className="mr-1" />
              {job.details?.employmentType}
            </li>
            <li className="flex sm:hidden items-center whitespace-nowrap font-normal">
              <DollarCurrencyIcon
                size={16}
                className="relative -top-1 mr-1 flex-shrink-0"
              />
              <AppLink to="/signup" className="text-indigo-500 hover:underline">
                Registrieren
              </AppLink>{" "}
              , um Gehalt zu sehen.
            </li>
            <Render
              if={!!job.details?.isRemote}
              else={
                <Render if={hasLocations}>
                  <li className="flex items-center">
                    <MapPinIcon
                      size={16}
                      className="relative -top-1 mr-1 flex-shrink-0"
                    />
                    <JobPostingLocations
                      locations={
                        job.businessLocations?.map((bl) => bl?.fullCityName) ??
                        []
                      }
                      limit={1}
                    />
                  </li>
                </Render>
              }
            >
              <li className="flex items-center">
                <MapPinIcon
                  size={16}
                  className="relative -top-1 mr-1 flex-shrink-0"
                />
                Remote {hasLocations && locations}
              </li>
            </Render>
          </ul>
          <ul className="flex flex-wrap gap-x-4 whitespace-nowrap text-sm sm:text-base">
            <CheckItem
              as="li"
              checked={job.details?.hasPersonnelResponsibility}
              title="Personalverantwortung"
              bold
            />
            <CheckItem
              as="li"
              checked={job.details?.hasTravelActivity}
              title="Reisetätigkeit"
              subTitle={
                isNumber(job.details?.maximumHomeOfficeDaysPerWeek)
                  ? job.details?.travelActivityPercentage + " %"
                  : undefined
              }
              bold
            />
            <CheckItem
              as="li"
              checked={job.details?.offersHomeOffice}
              title="Home-Office"
              subTitle={
                isNumber(job.details?.maximumHomeOfficeDaysPerWeek)
                  ? job.details?.maximumHomeOfficeDaysPerWeek +
                    " Tag(e) / Woche"
                  : undefined
              }
              bold
            />
          </ul>
          <ul className="flex flex-wrap mt-1 gap-1 sm:gap-2 sm:flex-wrap overflow-x-auto sm:overflow-x-visible">
            {job.programmingLanguages.map((pl) => (
              <Render key={pl?.id} if={!!pl}>
                <li className="text-base sm:text-lg font-medium text-indigo-500 mr-1">
                  {pl?.name}
                </li>
              </Render>
            ))}
            {job.softwareTechnologies.mostImportant.map((st) => (
              <Render key={st?.id} if={!!st}>
                <li>
                  <span className="bg-brand rounded text-white text-sm py-[2px] px-2 whitespace-nowrap">
                    {st?.name}
                  </span>
                </li>
              </Render>
            ))}
            {job.softwareTechnologies.important.map((st) => (
              <Render key={st?.id} if={!!st}>
                <li>
                  <span className="bg-brand rounded text-white text-sm py-[2px] px-2 whitespace-nowrap">
                    {st?.name}
                  </span>
                </li>
              </Render>
            ))}
            {job.softwareTechnologies.relevant.map((st) => (
              <Render key={st?.id} if={!!st}>
                <li>
                  <span className="bg-brand rounded text-white text-sm py-[2px] px-2 whitespace-nowrap">
                    {st?.name}
                  </span>
                </li>
              </Render>
            ))}
          </ul>
        </div>
        <div className="flex-shrink-0 hidden sm:block text-center md:text-right pt-1">
          <JobPostingSalary job={job} />
        </div>
      </div>
    </Fragment>
  );
};

export default JobSearchResultInternal;
